<template>
  <v-container fluid>
    <base-material-card
      title="Vor dem Wettkampf"
      color="grey darken-2"
    >
      <v-row>
        <v-col v-if="isMaster || isStbWWk(id)" lg="3" md="6" cols="12"><riegenkarten :id="id" :df="df" :r="r" :e="e" /></v-col>

        <v-col v-if="isMaster || isStbWWk(id)" lg="3" md="6" cols="12"><beteiligte :id="id" :e="e" :df="df" /></v-col>

        <v-col lg="3" md="6" cols="12"><mannschaftsuebersicht :id="id" :e="e" :r="r" :df="df" /></v-col>

        <v-col v-if="isMaster || isStbWWk(id)" lg="3" md="6" cols="12"><qrcode :id="id" :e="e" /></v-col>

        <v-col v-if="isMaster || isStbWWk(id)" lg="3" md="6" cols="12"><bodenmusik :id="id" :e="e" /></v-col>
      </v-row>
    </base-material-card>
    <base-material-card
      title="Nach dem Wettkampf"
      color="grey darken-2"
    >
      <v-row>
        <v-col v-if="isMaster || isStbWWk(id)" lg="3" md="6" cols="12"><riegenkarten :id="id" :df="df" :r="r" :e="e" /></v-col>

        <v-col lg="3" md="6" cols="12"><ergebnis :id="id" :df="df" :r="r" :e="e" /></v-col>

        <v-col lg="3" md="6" cols="12"><besteturner :id="id" :df="df" :r="r" :e="e" /></v-col>
      </v-row>
    </base-material-card>
    <base-material-card
      v-if="isMaster || isStbWWk(id)"
      title="Nach allen Wettkämpfen"
      color="grey darken-2"
    >
      <v-row>
        <v-col lg="3" md="6" cols="12"><tabellen :id="id" :e="e" /></v-col>

        <v-col lg="3" md="6" cols="12"><urkunden :id="id" :e="e" /></v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'downloads',

  components: {
    Riegenkarten: () => import('./pdf/riegenkarten'),
    Beteiligte: () => import('./pdf/beteiligte.vue'),
    Mannschaftsuebersicht: () => import('./pdf/mannschaftsuebersicht'),
    Qrcode: () => import('./pdf/qrcode'),
    Ergebnis: () => import('./pdf/ergebnis'),
    Besteturner: () => import('./pdf/besteturner'),
    Bodenmusik: () => import('./pdf/bodenmusik'),
    Tabellen: () => import('./pdf/tabellen'),
    Urkunden: () => import('./pdf/urkunden')
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    },
    f: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['isMaster', 'isStbWWk'])
  }
}
</script>

<style scoped>

</style>
